import React from "react";
// import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const isMobile = window.innerWidth <= 768;

// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import { faPoll } from "@fortawesome/free-solid-svg-icons";
// import { faPollH } from "@fortawesome/free-solid-svg-icons";

/*const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "ReservationCounter",
      icon: "file",
      description: "Aut debitis deserunt ea explicabo hic id incidunt, minus"
    },
    {
      name: "ReservationDesk",
      icon: "heart",
      description: "Aliquam amet assumenda debitis dicta distinctio eaque enim"
    },
    {
      name: "Cancun",
      icon: "headphones",
      description: "Consequatur doloremque illum libero nam. Hic, vitae?"
    }
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape" />
            <FontAwesomeIcon
              icon={["fa", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>
          <FontAwesomeIcon
            icon={faFile}
            className="icon fa-3x text-alternate"
          />

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};*/

const WhyUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="row gap-y">
          <div className="col-md-6">
            <FontAwesomeIcon icon={faFileAlt} className="fa-3x accent mb-3" />

            <h4 className="bold text-alternate">Career</h4>
            <p className="text-muted lead mb-5">
              Are you ready for a career change? Come work with us in a
              respectful, healthy, and flexible environment.
            </p>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <Link to="/careers" className="btn btn-primary text-contrast">
                Send your Resume
              </Link>
            </div>
          </div>
          {!isMobile && (
            <div className="col-md-6 ">
              <div className="rotated-mockups device-twin ">
                <div className="browser absolute shadow-lg sendToBack">
                  <img
                    src={require("assets/img/screens/portfolio/resume.png")}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
