import React from "react";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "./SubHeading.jsx";

export default class Services extends React.Component {
  text = {
    title: "Privacy Policy",
    subtitle: "Our data protection",
  };

  constructor({ props }) {
    super(props);
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    UP Outsourcing Privacy Policy
                  </h4>
                  <br />
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    <strong>
                      This policy sets how we use all entered data when you use
                      this website.
                    </strong>
                    <br />
                    Up Ousourcing is commited to ensuring that your privacy is
                    protected. This Privacy Policy can change over time, please
                    remember to review the statement before submiting your data.
                    <br />
                    <strong>What are we collecting?</strong>
                    <br />
                    This website collects basically information based on our
                    forms.
                    <br />
                    Our forms collects:
                    <br />
                    <ul>
                      <li>Full Name</li>
                      <li>e-Mail address</li>
                      <li>Company Name (when field is available)</li>
                    </ul>
                    <br />
                    Our Profile page collects:
                    <br />
                    <ul>
                      <li>Full Name</li>
                      <li>GitHub Address</li>
                      <li>e-Mail address</li>
                      <li>Answers from the questions with multiple choices</li>
                      <li>Comments (when field is available)</li>
                    </ul>
                    <br />
                    If you log-in with <b>Linkedin</b> on our Profile Page:
                    <br />
                    <ul>
                      <li>Full Name</li>
                      <li>e-Mail address</li>
                      <li>Profile Picture</li>
                      <li>Keys that provides us to read again above items</li>
                    </ul>
                    <strong>What we do with that information?</strong>
                    <br />
                    <ul>
                      <li>
                        The information entered using the forms are not stored.
                      </li>
                      <li> We have only a single copy; </li>
                      Your e-mail, however, will be stored for further contact;
                      <li>
                        We don't sell any stored data that came from this
                        website;
                      </li>
                      <li>
                        All information entered on Profile Page will be stored
                        for future contact about job opportunities in our
                        Company;
                      </li>
                    </ul>
                    <strong>We don't use your e-mail for spamming</strong>
                    <ul>
                      <li>
                        E-mails are answered individually. You may receive
                        automatic confirmation messages when using our Contact
                        Forms;
                      </li>
                      <li>
                        We don't have a marketing process, so you won't receive
                        news, updates and any marketing materials by email;
                      </li>
                      <li>
                        To follow updates from Up Outsourcing, you can follow us
                        on social networks that can be found at the footer in
                        this page;
                      </li>
                    </ul>
                    <strong>Privacy Policy Statement Changes</strong>
                    <br />
                    Update history
                    <br />
                    <ul>
                      <li>Current version set at June, 2019</li>
                    </ul>
                    <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
