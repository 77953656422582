import React from "react";
// import UPServices from "../shared/UPServices.jsx";
// import {
//   TabContent,
//   TabPane,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   Col
// } from "reactstrap";
// import classnames from "classnames";
// import { Container } from "reactstrap";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";

export default class Services extends React.Component {
  text = {
    title: "Startups",
    subtitle: "Being reliable since the beggining"
  };
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          {/* <UPServices /> */}

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    Starting up your business
                  </h4>

                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    When starting a new product, there are many questions to be
                    answered, and there are best practices to do it. Few steps
                    can help the new business to evaluate the idea:
                    <br />
                    <img
                      src={require("assets/img/screens/portfolio/prototype.png")}
                      alt="..."
                      style={window.innerWidth <= 500 ? { width: "380px" } : {}}
                    />
                    <br />
                    <strong>Idea:</strong>
                    <br />
                    The idea phase is the time to question, observe, check the
                    networking, and experiment with the idea.
                    <br />
                    <strong>Problem:</strong>
                    <br />
                    Discovering the job to be done is the next step: Start
                    exploring the problem or the need from the customer, using
                    functional, social, and emotional spheres. We need to be
                    sure that we are digging into a problem worth solving.
                    <br />
                    <strong>Solution:</strong>
                    <br />
                    Prototyping the Minimum Valuable Product or, we also call it
                    Minimum Awesome Product, is the next step. Building the
                    basics will save time and make us understand if this is the
                    right direction for the software. Full product ideas are
                    essential, however, it is more accurate to solve the central
                    pain of the customer before understanding if all theorical
                    features have the same importance as the main features.
                    <br />
                    <strong>Business Model:</strong>
                    <br />
                    After the main features are done and working correctly, it's
                    time to build the business model, pricing, and market
                    strategies. After these steps, leaving the prototype stage
                    and moving into its own business format is the next goal.
                  </p>
                  <br />
                  <h4 className="bold text-alternate">We can help you!</h4>
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    We have Certificated Managers that can help with you to
                    address:
                    <br />
                    <ul>
                      <li>
                        How to recognize and question assumptions and
                        constraints so as to identify and capitalize on
                        opportunities;
                      </li>
                      <li>
                        Learn to change the rules of the game by creating
                        innovative value propositions and discovering new market
                        positions for sustained competitive advantage;
                      </li>
                      <li>
                        Developing with Agile practices can handle any changes
                        to adapt the solution to the market;
                      </li>
                    </ul>
                    <br />
                  </p>
                  <br />

                  <a
                    className="more-link btn btn-outline-alternate more-link mt-0"
                    href="/services"
                    style={
                      window.innerWidth <= 768 ? { marginBottom: "13em" } : {}
                    }
                  >
                    ... More Services
                  </a>
                  <a
                    className="more-link btn btn-outline-alternate more-link mt-0 padding-left-btn-touch"
                    href="/contato"
                    style={
                      window.innerWidth <= 768 ? { marginBottom: "13em" } : {}
                    }
                  >
                    Get in touch
                  </a>
                </div>

                {window.innerWidth >= 769 && (
                  <div className="col-md-6">
                    <div className="rotated-mockups device-twin">
                      <div className="browser absolute shadow-lg">
                        <img
                          src={require("assets/img/screens/portfolio/real_report_down.png")}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
