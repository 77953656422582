const defaultLanguage = 'en';
const enabledLanguages = [
  'en',
  'pt-BR',
];

const userLocale = {
  loadLanguage: async () => {
    if (!localStorage.getItem('language')) {
      let language;

      try {
        const response = await fetch('https://ipapi.co/json/');
        const country = (await response.json())['country'];
        if (country) {
          language = (country == 'BR' ? 'pt-BR' : defaultLanguage);
        } else {
          throw 'Country unavailable.';
        }
      } catch (error) {
        language = (navigator && navigator.language.startsWith('pt')) ? 'pt-BR' : defaultLanguage;
      }

      localStorage.setItem('language', language);
    }

    return userLocale.getLanguage();
  },
  getLanguage: () => {
    const language = localStorage.getItem('language');

    if (language && enabledLanguages.includes(language)) {
      return language;
    }

    return defaultLanguage;
  }
}

export default userLocale;
