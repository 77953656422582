import React from "react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MoreInfoButton from "components/MoreInfoButton";
const isMobile = window.innerWidth <= 700;

const UPSobre = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              Formamos Times Ágeis de Desenvolvimento para Empresas de Tecnologia 
            </h4>
            <p className="text-muted lead mx-auto">
              Somos uma empresa de Outsourcing, sediada em Bauru, centro do estado de São Paulo.<br /><br />
              Construímos equipes tanto de desenvolvimento web como de gestão para apoiar seu produto ou ideia; além de  cuidar de toda a burocracia.<br /><br />
              Com experiência de mais de 10 anos no gerenciamento de equipes multinacionais de desenvolvimento e gestão de produtos e projetos, já ajudamos diversas empresas e startups a criar ou aprimorar seus produtos.<br /><br />

              Nosso time de  <strong>Gestão de Pessoas </strong> busca, além das habilidades técnicas e experiências, o alinhamento cultural do candidato com o projeto.<br /><br />
              Usamos  <strong>Métodos e estruturas Ágeis </strong>, que nos garante uma maior rapidez na entrega para o cliente e permite uma maior flexibilidade de trabalho.<br /><br />
              Hoje contamos com um processo de desenvolvimento exclusivamente <strong>remoto </strong><br /><br />
              Somos uma empresa plural, que busca oferecer um ambiente de trabalho feliz, acolhedor e de respeito a todos que nos ajudam a construir o futuro. Com um escritório moderno e preocupado com as <strong> questões ambientais </strong>, e também adaptado aos novos desafios da atualidade, distanciamento maior entre as estações de trabalho, sistema de filtragem e purificação de ar.<br />

              <br />
             
            </p>
            <br /> <br />
            <h4 className="bold text-alternate">
             Como chegamos aqui?
            </h4>
            <p className="text-muted lead mx-auto">
              A UP é uma empresa que nasceu da junção das experiências de seus sócios,  <a href="https://www.linkedin.com/in/gilneilongo/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} /> Gilnei Longo
              </a> e  <a href="https://www.linkedin.com/in/colafati" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} /> Everton Colafati
              </a>, ambos com mais de 20 anos de experiência na área e que no ano de 2007 trabalharam em seu primeiro projeto juntos até o ano de 2010; voltando a trabalhar juntos no ano de 2013 já como empreendedores, projeto este que em 2019 passou por uma reestruturação em busca de crescimento surgindo assim a UP. <br /> <br />
              Uma empresa que busca além do profissional a <strong>valorização humana </strong> o  <strong>respeito às diversidade </strong>, aplicando no dia a dia seus valores. <br />

            </p>
            <br /> <br />


            <h4 className="bold text-alternate">
             Nossos Valores
            </h4>
            <p className="text-muted lead mx-auto">
              Nossos valores além de nossas ações, também representam nossos anseios para o futuro.
              <br /> <br />
              <strong>Ética do Trabalho </strong><br />
              Temos um trabalho pautado na <strong>ética</strong> e <strong>honestidade</strong>, prezamos a colaboração mútua e buscando sempre conciliar os anseios do colaborador com as necessidades dos projetos.

              <br /><br /> <strong> Inovação </strong><br />Buscamos sempre novas e melhores formas de fazer ou resolver algo, estamos abertos e dispostos a ouvir novas idéias e contribuir para o sucesso delas.

              <br /><br /> <strong> Qualidade das Equipes </strong><br /> Acreditamos que <strong>formar</strong> uma equipe é mais importante que montá-la, gestores e equipes altamente qualificadas, acompanhamento individualizado.

              <br /><br /> <strong> Aprender Sempre  </strong><br />Sabemos que o aprendizado nunca para, por isso oferecemos meios de <strong>crescimento contínuo</strong> e um ambiente que busca enxergar sempre o melhor das situações.

              <br /><br /> <strong> Comunicação assertiva  </strong><br />Mais do que saber falar, saber ouvir; compartilhamos da ideia de que a maioria dos problemas pode ser evitado com uma <strong>comunicação eficiente</strong>.

              <br /><br />  <strong> Transparência nos negócios </strong><br /> Por sermos uma empresa B2B, primamos pela transparência tanto com fornecedores quanto colaboradores, <strong>honestidade</strong> é a base de nosso crescimento.

              <br /><br /> <strong> Responsabilidade socioambiental </strong><br /> Procuramos adotar <strong>boas práticas ambientais</strong> desde a redução do uso de recursos como água, energia elétrica e papel , medidas fitossanitárias contra o covid, respeito à diversidade e apoio a projetos da área.

            </p>

            <br /> <br />
            <br /> <br /> <br /> <br />
            <br /> <br /> 
          </div>
          {isMobile && <div className="marginForMobileServices"></div>}
          {!isMobile && (
            <div className="col-md-6">
              <div className="rotated-mockups device-twin">
                <div className="browser absolute shadow-lg">
                  <img
                    src={require("assets/img/screens/portfolio/bauru.jpg")}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default UPSobre;
