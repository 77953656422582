import React from "react";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "./SubHeading.jsx";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
export default class Services extends React.Component {
  text = {
    title: "Talent Pool",
    subtitle: "Envie seu currículo antecipadamente!",
  };

  constructor({ props }) {
    super(props);
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    Banco de Talentos da Up Outsourcing
                  </h4>
                  <br />
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    A Up Outsourcing oferece oportunidades constantemente e se
                    você estiver interessado em trabalhar conosco, você pode se
                    adiantar enviando seu currículo.
                    <br />
                    Veja as nossas 3 áreas de atuação abaixo! Caso se encaixe em
                    uma dessas áreas, mande seu currículo para &nbsp;
                    <a href="mailto:rh@upoutsourcing.com">
                      rh@upoutsourcing.com
                    </a>
                    <br />
                    Quando houver uma oportunidade compatível com seu perfil,
                    entraremos em contato primeiramente com você!
                    <div>
                      <br /> &nbsp;
                      <br /> <br />
                    </div>
                    <br /> <strong>Áreas de Atuação e Tecnologias</strong>
                    <br />
                    Nós trabalhamos primordialmente com tecnologias para web:
                    <br /> <br /> &nbsp;
                    <Row>
                      <Col lg="12" className="mx-lg-auto">
                        <Fade bottom key="1">
                          <div
                            className="card shadow-box shadow-hover mb-3"
                            key="1"
                          >
                            <div className="card-header py-3">
                              <div className="card-body">
                                Desenvolvedores Web
                              </div>
                            </div>
                            <div className="card-body ">
                              <strong>Front-End</strong>
                              <p>
                                Trabalhando como desenvolvedor Front-End você
                                vai atuar na construção de soluções web que
                                envolvem primordiamente HTML, Javascript e CSS
                                para projetar ou construir os elementos que
                                fazem parte da experiência do usuário no projeto
                                ou solução. Essa oportunidade exige resolução de
                                problemas, experiência com as linguagens citadas
                                e principalmente a interação com os outros
                                especialistas do time, como o UX Designer (que
                                cuida da experiência do usuário), UI (cuidando
                                da interface do usuário), desenvolvedor Back-End
                                e Analistas de Qualidade, dentro do processo
                                Ágil da Up Outsourcing.
                              </p>
                              <strong>Backend</strong>
                              <p>
                                Trabalhando como desenvolvedor Back-End você vai
                                atuar na construção de soluções de arquitetura
                                que envolvem trabalhos mais próximos dos
                                servidores web que envolvam as tecnologias
                                definidas pelo projeto. Oferecemos
                                primordialmente oportunidades para programadores
                                PHP, envolvendo frameworks como Laravavel e
                                Symfony.
                              </p>
                              <strong>React / React Native</strong>
                              <p>
                                React se tornou uma tecnologia em alta no
                                mercado pela sua alta velocidade de
                                processamento de redenderização dos componentes
                                DOM, flexibilidade da renderização, reutilização
                                dos componentes, escalabilidade, além de trazer
                                mais facilidade na construção de aplicativos
                                móveis (iOS e Android).
                              </p>
                              <br />
                              <i>
                                Principais tecnologias: PHP, Symfony, Laravel,
                                React, React Native, Javascript, HTML, CSS
                              </i>
                              <br />
                            </div>
                          </div>
                        </Fade>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="12" className="mx-lg-auto">
                        <Fade bottom key="1">
                          <div
                            className="card shadow-box shadow-hover mb-3"
                            key="1"
                          >
                            <div className="card-header py-3">
                              <div className="card-body">
                                Analistas de Qualidade
                              </div>
                            </div>
                            <div className="card-body ">
                              <p>
                                O profissional de Qualidade atua primordiamente
                                em validar os trabalhos apresentados pelo time,
                                em busca de problemas não detectados pelo
                                desenvolvimento front-end e back-end com o
                                objetivo de encontrar essas falhas o mais rápido
                                possível para que os usuários do produto web não
                                tenham dificuldade em utilizar. Além do trabalho
                                de testes, valorizamos muito a Automação de
                                Testes, utilizando tecnologias como o Cypress,
                                Selenium, etc. Esse profissional de Qualidade
                                precisa ser comunicativo e interagir com o time
                                o tempo todo para manter o produto funcional,
                                priorizando sempre o usuário.
                              </p>
                              <br />
                              <i>Principais tecnologias: Cypress, Selenium</i>
                              <br />
                            </div>
                          </div>
                        </Fade>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="mx-lg-auto">
                        <Fade bottom key="1">
                          <div
                            className="card shadow-box shadow-hover mb-3"
                            key="1"
                          >
                            <div className="card-header py-3">
                              <div className="card-body">
                                Desenvolvedores ou Analistas Ágeis
                              </div>
                            </div>
                            <div className="card-body ">
                              <p>
                                Nossos times de desenvolvimento são montados de
                                acordo com a avaliação das skills necessárias
                                para cara posição e também avaliamos diversas
                                características como:
                                <ul>
                                  <li>Facilidade de Comunicação </li>
                                  <li>Colaboração com o time</li>
                                  <li>Auto-organização</li>
                                  <li>
                                    Trabalho orientado ao resultado ou objetivo
                                    do projeto
                                  </li>
                                </ul>
                                Todos nossos times trabalham utilizando
                                Metodologias Ágeis (Scrum ou Kanban) com
                                processos bem definidos e com muito respeito à
                                saúde de nossos colaboradores.
                              </p>
                            </div>
                          </div>
                        </Fade>
                      </Col>
                    </Row>
                    <strong> Estamos aguardando seu currículo! </strong> <br />
                    Corre lá:{" "}
                    <a href="mailto:rh@upoutsourcing.com">
                      rh@upoutsourcing.com
                    </a>
                  </p>

                  <br />
                </div>{" "}
                <br />
              </div>{" "}
              <br />
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
