import React from "react";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";
import UPSobre from "../shared/UPSobre";

class Sobre extends React.Component {
  text = {
    title: "Sobre",
    subtitle: "Hora de nos conhecer melhor"
  };
  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <UPSobre />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Sobre;
