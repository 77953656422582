import React from "react";
import { Container } from "reactstrap"; //Row, Col, UncontrolledCollapse
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import SubHeading from "./SubHeading.jsx";

import { LinkedIn } from "react-linkedin-login-oauth2";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

require("dotenv").config();
const redirectURI = process.env.REACT_APP_LINKEDIN_CALLBACK + "/linkedin";

// console.log("redirectURI=" + redirectURI);

class Career extends React.Component {
  state = {
    errorMessage: "",
    code: "",
  };

  saveData = () => {
    let self = this;
    console.log("saving: ");
    console.log(
      self.first_name + " " + self.last_name + " id: " + self.linkedin_id
    );
  };

  handleSuccess = (data) => {
    localStorage.setItem("code", data.code);
    this.props.history.push({
      pathname: "/profile",
    });

    this.setState({
      code: data.code,
      errorMessage: "",
    });
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  text = {
    title: "Login",
    subtitle: "Perfil Profissional",
  };
  componentDidMount() {
    localStorage.removeItem("code");
    localStorage.removeItem("internalToken");
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}

          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <section className="section why-choose-us ">
            <Container className="bring-to-front ">
              <div>
                <h4>Entre com seu linked-in</h4>
                <p>
                  Utilizamos o login vinculado com o Linked-in para nosso banco
                  de currículos. Se você não possui Linked-in, pode nos enviar
                  por e-mail.
                </p>
                <LinkedIn
                  clientId={process.env.REACT_APP_CLIENT_ID}
                  redirectUri={redirectURI}
                  scope="r_liteprofile+r_emailaddress"
                  state="34232423"
                  onFailure={this.handleFailure}
                  onSuccess={this.handleSuccess}
                  supportIE
                  redirectPath="/linkedin"
                >
                  <img
                    src={require("assets/img/screens/profile/linkedin.png")}
                    alt="Log in with Linked In"
                    style={{ maxWidth: "300px" }}
                  />
                </LinkedIn>
                <p>
                  Importante: não compartilhamos informações com terceiros.
                  Consulte nossa
                  <a href="/privacy-policy"> Política de Privacidade</a>
                </p>
                {errorMessage && <div>{errorMessage}</div>}
                &nbsp;
                <br />
                <br />
                <br />
              </div>
            </Container>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Career;
