import React from "react";
// import { Container } from "reactstrap";
// import MoreInfoButton from "components/MoreInfoButton";
const isMobile = window.innerWidth <= 768;

const AboutUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container bring-to-front">
        <div className="row gap-y ">
          <div className={isMobile ? "col-md-12" : "col-md-6"}>
            <h4 className="bold text-alternate">Main Services</h4>
            <p className="text-muted lead mx-auto">
              UP Outsourcing is more than only a web development, we can help
              you to build and manage your team as well.
            </p>

            {/* <div className="wideX    shadow">
              <ul className="list-unstyled">
                <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                  <img
                    src={require("assets/img/screens/portfolio/consulting.png")}
                    alt="..."
                  />

                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">Consulting</h5>
                    <p className="text-muted ">consulting consulting</p>
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0"
                      href="/agiledevelopment"
                    >
                      Find out more...
                    </a>
                  </div>
                </li>
              </ul>
            </div> */}

            <div className="wideX   shadow">
              <ul
                className={`list-unstyled ${
                  isMobile ? "marginForMobileServices" : ""
                }`}
              >
                <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                  <img
                    src={require("assets/img/screens/portfolio/management.png")}
                    alt="..."
                  />
                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">
                      Management and OutSourcing
                    </h5>
                    <p className="text-muted ">
                      Team Management is our core business. We have certified
                      Managers that can help your company to build a development
                      team, and follow it up properly
                    </p>
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0"
                      href="/outsourcing"
                    >
                      Find out more...
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="wideX  shadow">
              <ul
                className={`list-unstyled ${
                  isMobile ? "marginForMobileServices" : ""
                }`}
              >
                <li className="media flex-column flex-md-row text-center text-md-left">
                  <img
                    src={require("assets/img/screens/portfolio/scrum.png")}
                    alt="..."
                    className="img-list-services"
                  />
                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">Agile Development</h5>
                    <p className="text-muted ">
                      We have more than ten years practicing Agile Guidelines.
                      Scrum and Kanban mindsets helps software development and
                      make deliveries faster and more reliable. Each Company has
                      its timing and we can help to enhance your process.
                    </p>
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0"
                      href="/agiledevelopment"
                    >
                      Find out more...
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="wideX    shadow">
              <ul
                className={`list-unstyled ${
                  isMobile ? "marginForMobileServices" : ""
                }`}
              >
                <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                  <img
                    src={require("assets/img/screens/portfolio/startups.png")}
                    alt="..."
                  />

                  <div className="media-body mt-3 mt-md-0">
                    <h5 className="bold mt-0 mb-1">Startups</h5>
                    <p className="text-muted ">
                      Startups are the fastest way to build a Company from
                      scratch. We focus on lower costs and a reliable team to
                      help you deliver your prototype and get investors asap.
                    </p>
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0"
                      href="/startup"
                    >
                      Find out more...
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <br />
            <br />
            <br />
            {isMobile && <div className="marginForMobile"></div>}
          </div>

          {!isMobile && (
            <div className="col-md-6">
              <div className="rotated-mockups device-twin">
                <div className="browser absolute shadow-lg">
                  <img
                    src={require("assets/img/screens/portfolio/real_report_down.png")}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
