import React from "react";
// import UPServices from "../shared/UPServices.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";
// const isMobile = window.innerWidth <= 768;

require("dotenv").config();

function onChange(value) {
  console.log("Captcha value:", value);
}

export default class Services extends React.Component {
  text = {
    title: "Outsourcing",
    subtitle: "Do you need Outsourcing services?",
  };

  constructor({ props }) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      returnMessage: "",
      submittedClass: " hiddenDiv ",
    };

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();

    data.append("action", "getintouch");
    data.append("name", this.name.value);
    data.append("companyname", this.companyname.value);
    data.append("email", this.email.value);
    data.append("message", this.message.value);
    data.append("auth", process.env.REACT_APP_SENDGRID_API_KEY);

    fetch("/contact.php", {
      method: "POST",
      body: data,
    }).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson[0] === "success") {
          this.setState({ submittedClass: " submitted " });
        } else {
          alert("Sorry, something went wrong, please try again.");
          window.location.reload(false);
        }
      });
    });
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          {/* <UPServices /> */}

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    Schedule a free consultation with our team!
                  </h4>

                  <br />
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    <strong>Let's chat for Q&A </strong>
                    <br />
                    To understand more about our services and if costs are
                    compatible with your plan, we can schedule a meeting to
                    discuss it.
                    <br />
                    Usually, 1 to 2 hours is enough to understand what services
                    of Development Ousourcing we are able to offer to help your
                    business.
                    <br /> After this call we will send an estimative of costs
                    for you without any costs or obligation.
                    <br />
                  </p>
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    <strong> Schedule a free consultation: </strong>
                  </p>
                  <form
                    className={`form text-center ${this.state.submittedClass} `}
                    data-response-message-animation="slide-in-left"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input--group">
                      <input
                        id="name"
                        type="name"
                        name="name"
                        className="form-control form-control-lg text-center"
                        placeholder="Enter your full name"
                        required
                        ref={(ref) => {
                          this.name = ref;
                        }}
                      />
                      <br />
                      <input
                        id="companyname"
                        type="companyname"
                        name="companyname"
                        className="form-control form-control-lg text-center"
                        placeholder="Company Name"
                        required
                        ref={(ref) => {
                          this.email = ref;
                        }}
                      />
                      <br />
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className="form-control form-control-lg text-center"
                        placeholder="Contact E-mail"
                        required
                        ref={(ref) => {
                          this.email = ref;
                        }}
                      />
                      <br />
                      <textarea
                        id="message"
                        name="message"
                        className="form-control form-control-lg text-center"
                        placeholder="How can we help you?"
                        required
                        ref={(ref) => {
                          this.message = ref;
                        }}
                      />
                    </div>
                    <div
                      className="recaptcha-area"
                      style={
                        window.innerWidth <= 768
                          ? { flexDirection: "column" }
                          : {}
                      }
                    >
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <p>
                        Make sure you read our{" "}
                        <a href="/privacy-policy" target="_blank">
                          Privacy Policy
                        </a>{" "}
                        Statement.
                        <br />
                        We don't send any marketing, promotions or company
                        updates emails. Only answers to this contact form.
                      </p>
                      <button
                        type="submit"
                        className="btn btn-lg btn-alternate"
                        style={
                          window.innerWidth <= 768
                            ? { marginTop: 10 }
                            : { marginLeft: 40 }
                        }
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
                <div className="response-message  hiddenDiv">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="fa-3x"
                  />
                  <p className="font-md m-0">Sent!</p>
                  <p className="response">
                    Thank you! Soon we will get in touch. <br />
                    <strong>Thank you!</strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
