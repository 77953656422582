import React from "react";
// import { Container, Row, Col, Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MoreInfoButton from "components/MoreInfoButton";

import Swiper from "react-id-swiper";
import "swiper/dist/css/swiper.css";

let slidesPerView = window.innerWidth <= 500 ? 2 : 4;
if (slidesPerView === 4) {
  slidesPerView = window.innerWidth <= 769 ? 3 : 4;
}

console.log(slidesPerView);
const params = {
  loop: true,
  autoplay: {
    delay: 2000,
    disableOnIteration: false,
  },
  slidesPerView: slidesPerView || 5,
  spaceBetween: 40,
};

// const renderPartners = () => {
//   let partners = [];

//   for (let i = 1; i <= 5; ++i) {
//     partners.push(
//       <NavItem className="mr-3 py-2" key={i}>
//         <img
//           src={require(`assets/img/logos/${i}.png`)}
//           className="icon"
//           alt=""
//         />
//       </NavItem>
//     );
//   }

//   return partners;
// };

const Clients = () => {
  return (
    // <section className="section why-choose-us">
    //   <Container className="bring-to-front">
    //     <div className="container">
    //       <Row>
    //         <Col md="6">
    //           <div className="section-heading">
    //             <FontAwesomeIcon
    //               icon={["fas", "user-tie"]}
    //               className="fa-3x accent mb-3"
    //             />

    //             <h3 className="text-alternate heading-line">Our clients</h3>

    //             <p className="lead">
    //               We have 7+ years working with high performance websites, test
    //               automation, optimization and consulting. Below you will find
    //               more details for each customer case and more technical
    //               details.
    //             </p>
    //           </div>
    //           <ul className="list-unstyled">
    //             <li className="media flex-column flex-md-row text-center text-md-left">
    //               <img src={require(`assets/img/logos/RD.png`)} alt="..." />

    //               <div className="section-heading">
    //                 {/* <h5 className="bold mt-0 mb-1">ReservationCounter.com</h5> */}
    //                 <p>
    //                   Hotel Booking System with high performance loading and
    //                   optimised code using Symfony and many API connections.
    //                 </p>
    //               </div>
    //             </li>

    //             <li className="media flex-column flex-md-row text-center text-md-left">
    //               <img src={require(`assets/img/logos/RC.png`)} alt="..." />

    //               <div className="section-heading">
    //                 {/* <h5 className="bold mt-0 mb-1">ReservationDesk.com</h5> */}
    //                 <p>
    //                   Hotel Booking System with high performance loading and
    //                   optimised code using Symfony and many API connections.
    //                 </p>
    //               </div>
    //             </li>

    //             <li className="media flex-column flex-md-row text-center text-md-left">
    //               <img src={require(`assets/img/logos/RDEALS.png`)} alt="..." />

    //               <div className="section-heading">
    //                 {/* <h5 className="bold mt-0 mb-1">ReservationDeals.com</h5> */}
    //                 <p>
    //                   Hotel Booking System with high performance loading and
    //                   optimised code using Symfony and many API connections.
    //                 </p>
    //               </div>
    //             </li>

    //             <li className="media flex-column flex-md-row text-center text-md-left">
    //               <img src={require(`assets/img/logos/TC.png`)} alt="..." />

    //               <div className="section-heading">
    //                 {/* <h5 className="bold mt-0 mb-1">TicketCounter.com</h5> */}
    //                 <p>
    //                   Hotel Booking System with high performance loading and
    //                   optimised code using Symfony and many API connections.
    //                 </p>
    //               </div>
    //             </li>

    //             <li className="media flex-column flex-md-row text-center text-md-left">
    //               <img src={require(`assets/img/logos/CANCUN.png`)} alt="..." />
    //               <div className="section-heading">
    //                 {/* <h5 className="bold mt-0 mb-1">Cancun.com</h5> */}
    //                 <p>
    //                   Hotel Booking System with high performance loading and
    //                   optimised code using Symfony and many API connections.
    //                 </p>
    //               </div>
    //             </li>
    //           </ul>
    //           {/* <Nav>{renderPartners()}</Nav> */}
    //         </Col>
    //       </Row>
    //     </div>
    //   </Container>
    // </section>

    <section className="section">
      <div className="container  ">
        {/* border-bottom */}
        <FontAwesomeIcon
          icon={["fas", "user-tie"]}
          className="fa-3x accent mb-3"
        />

        <h3 className="text-alternate heading-line ">Our clients</h3>

        <Swiper {...params}>
          {[1, 2, 3, 4, 5].map((i) => (
            <div className="swiper-slide ">
              <img
                src={require(`assets/img/logos/${i}.png`)}
                className="img-responsive"
                alt=""
                style={{ maxWidth: "200px" }}
              />
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Clients;
