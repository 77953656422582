import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { faFilePdf, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const UPTeam = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">Management Team</h4>
            <p className="text-muted lead mx-auto">
              Our leading team is formed by more than 10+ years professionals
            </p>
          </div>
        </div>

        <div className="bg-contrast shadow rounded p-5 lessMarginTop ">
          <div className="gap-y row ">
            <div className="col-md-6">
              <div className="teamBoxProfile ">
                <img
                  src={require("assets/img/screens/portfolio/aboutus_gilnei.png")}
                  alt="Gilnei"
                />
                <hr className="w-25 bw-2 border-alternate mt-3 mb-4"></hr>
                <h4 className="bold text text-capitalize ">Gilnei Longo</h4>
                <div>
                  <a
                    href="https://www.linkedin.com/in/gilneilongo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fa-2x  accent mb-3 teamProfileSocial"
                    />
                  </a>
                  <a
                    href="https://twitter.com/_gilnei_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fa-2x  accent mb-3 teamProfileSocial"
                    />
                  </a>
                </div>
                <ul className="list-unstyled why-icon-list">
                  <li className="list-item">
                    <p className="font-regular text-primary">
                      Agile Coach, Scrum Master, Product Owner, Scrum Developer
                      and Product Manager
                      <br />
                      <span className="font-sm text-muted ">
                        I have more than 18 years of professional IT experience.
                        I started as a web developer in the 90s, and technology
                        became my life. I love testing new ideas, processes, and
                        new products. I worked with different companies'
                        maturity levels, and I always tried to be better and
                        provide better solutions that could make things easier.
                        After years of developing for the internet, I started
                        managing teams, products, and now I'm working with
                        multiple projects and teams between different countries.
                        More information at linkedin
                        <br />
                        <br /> I graduated in System Information (FGP Brazil)
                        and Project Manager MBA (FGV Brazil). My last
                        specialization was Innovation: From Creativity to
                        Entrepreneurship Capstone from Illinois University.
                      </span>
                    </p>
                  </li>

                  <li className="list-item"></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="teamBoxProfile ">
                <img
                  src={require("assets/img/screens/portfolio/about_us_everton.jpeg")}
                  alt="Everton"
                />
                <hr className="w-25 bw-2 border-alternate mt-3 mb-4"></hr>
                <h4 className="bold text text-capitalize ">Everton Colafati</h4>
                <div>
                  <a
                    href="https://www.linkedin.com/in/colafati/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fa-2x  accent mb-3 teamProfileSocial"
                    />
                  </a>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fa-2x  accent mb-3 teamProfileSocial"
                    />
                  </a>
                </div>
                <ul className="list-unstyled why-icon-list">
                  <li className="list-item">
                    <p className="font-regular text-primary">
                      Scrum Master, Product Owner, Scrum Developer and Product
                      Manager
                      <br />
                      <span className="font-sm text-muted ">
                        text here describing
                      </span>
                    </p>
                  </li>

                  <li className="list-item"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UPTeam;
