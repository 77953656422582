import React from "react";
import { Container, Row, Col, Nav } from "reactstrap";

//const SubHeading = () => {
class SubHeading extends React.Component {
  text = {
    title: this.props.title,
    subtitle: this.props.subtitle
  };

  render() {
    return (
      <header className="header alter3-Subheader section gradient gradient-primary-auxiliary text-contrast">
        <div className="shapes-container">
          <div className="static-shape shape-ring-1" />
          <div className="static-shape shape-ring-2" />
          <div className="static-shape shape-circle-1" />

          <div className="static-shape pattern-dots-1" />
          <div className="static-shape pattern-dots-2" />

          <div className="static-shape ghost-shape ghost-shape-1" />
        </div>

        <Container className="bring-to-front">
          <Row>
            <Col md="6">
              <h1 className="display-md-4 font-md">{this.text.title}</h1>
              <p className="lead">{this.text.subtitle}</p>

              <Nav className="mt-5" tag="nav" />
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default SubHeading;
