import React from "react";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "./SubHeading.jsx";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
export default class Services extends React.Component {
  text = {
    title: "Nossos Valores",
    subtitle: "Conheça mais sobre a Missão e Objetivos da Up Outsourcing",
  };

  constructor({ props }) {
    super(props);
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    Missão e Objetivos da Up Outsourcing
                  </h4>
                  <br />
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    <ul>
                      <li>
                        Nossa <strong>missão</strong> é de ajudar nossos
                        clientes a criar e desenvolver sistemas para internet,
                        visando sempre a qualidade do produto e a qualidade de
                        vida dos nossos colaborares;
                      </li>
                      <li>
                        Nosso Processo de Desenvolvimento é 100% embasado em
                        Metodologias Ágeis, focando em Pessoas, Software
                        Funcional, Colaboração com Cliente e Resposta Rápida à
                        mudanças;
                      </li>
                      <li>
                        <strong>Incentivamos</strong> o Engajamento, Inovação,
                        Diversidade, Pertença, Cultura, Conexão e Propósito;
                      </li>
                    </ul>
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
