import React from "react";

// shared page components
import ProfessionalServices from "../shared/ProfessionalServices.jsx";
// import MultipleDesignBlocks from "../shared/MultipleDesignBlocks.jsx";
import CertifiedProfessionals from "../shared/CertifiedProfessionals.jsx";
//import Pricing from "../shared/Pricing.jsx";
//import Integration from "../shared/Integration.jsx";
// import WhyUs from "../shared/WhyUs.jsx";
import Career from "../shared/Career.jsx";
//import Faqs from "../shared/Faqs.jsx";
//import StartFree from "../shared/StartFree.jsx";
//import Subscribe from "../shared/Subscribe.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 3 page components
import Heading from "../alter3/Heading.jsx";
import IsometricMockups from "../alter3/IsometricMockups.jsx";
import OurClients from "../alter3/OurClients.jsx";

class Alternative3 extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main" className="main-home-page">
          {/* Alternative 3 specific components */}
          <Heading />
          {/* <MultipleDesignBlocks /> */}
          <IsometricMockups />
          {/*<WhyUs />*/}
          <CertifiedProfessionals />
          <OurClients />
          <ProfessionalServices />
          <Career />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Alternative3;
