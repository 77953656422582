import React from "react";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "./SubHeading.jsx";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
export default class Services extends React.Component {
  text = {
    title: "Bauru",
    subtitle:
      "Conheça mais o desenvolvimento de tecnologia no Interior de São Paulo",
  };

  constructor({ props }) {
    super(props);
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">Bauru</h4>
                  <br />
                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "18em" }
                          : { width: "30em" }
                        : {}
                    }
                  >
                    empresas de tecnolgoia (so a tecnologia)
                    <br/>
                    
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
