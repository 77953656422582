import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/screens/portfolio/up_logo_blue.png")}
              alt=""
              className="logo"
            />

            <p>UP Outsourcing. Bauru, SP - Brazil</p>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className="py-2 text-contrast" href="/about">
                About
              </NavLink>
              <NavLink className="py-2 text-contrast" href="/services">
                Services
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            {/* <Nav className="flex-column" tag="nav">
              <NavLink className="nav-item py-2 text-contrast" href="/careers">
                Careers
              </NavLink>
            </Nav> */}

            <Nav className="flex-column" tag="nav">
              <NavLink className="py-2 text-contrast" href="/careers">
                Careers
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" href="/contato">
                Contact
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <h6 className="py-2 small">Follow us</h6>

            <nav
              className="nav nav-follow"
              style={
                window.innerWidth <= 500
                  ? {
                      width: "100%",
                      paddingRight: 100,
                      paddingLeft: 110,
                      justifyContent: "space-around",
                    }
                  : {}
              }
            >
              <a
                href="https://www.facebook.com/upoutsourcingbr"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-circle btn-sm brand-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>

              <a
                href="https://www.linkedin.com/company/upoutsourcing/"
                className="btn btn-circle btn-sm brand-twitter"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>

              <a
                href="https://www.glassdoor.com.br/Visão-geral/Trabalhar-na-UP-Outsourcing-EI_IE3037134.13,27.htm"
                className="btn btn-circle btn-sm brand-twitter"
              >
                <img
                  src={require("assets/img/screens/portfolio/glassdoor.png")}
                  alt=""
                  className="logo"
                />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              © {new Date().getFullYear()}
              <a
                href="https://www.upoutsourcing.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp; UP Outsourcing
              </a>
              &nbsp; . All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
