import React from "react";
import { Container, Row, Col } from "reactstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade, Zoom } from "react-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ADDING MORE ICONS: check / lapp/src/laap.js
const FeaturesCantMiss = () => {
  const iPad = window.innerWidth <= 768 ? true : false;
  return (
    <section className="section alter3-partners bg-contrast edge top-left">
      <Container className="bring-to-front pb-md-9">
        <Row>
          <Col md={iPad ? "12" : "6"}>
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "award"]}
                className="fa-3x accent mb-3"
              />
              <h3 className="text-alternate heading-line">
                Certified Professionals
              </h3>
            </div>

            <ul className="list-unstyled">
              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-icpacc.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Agile Coach Certified</h5>
                  <p className="m-0">
                    An Agile coach serves as a catalyst to the agile team,
                    facilitating agile thinking and practices and empowering the
                    team to reach their goals through improved team practices.
                  </p>
                  <br />
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-csd.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-5 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Certfied Scrum Developer</h5>
                  <p className="m-0">
                    All of our team leaders are Certified as Scrum Developers.
                    These Developers had formal training to understand how to
                    develop based on the Scrum or Agile Principles. Scrum
                    Alliance certifies all of them.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-csm.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Certified Scrum Management</h5>
                  <p className="m-0">
                    Our managers are certified from Scrum Alliance on the best
                    practices for a higher level of performance when managing a
                    multi-team towards the fastest possible development with
                    quality and continuous delivery.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4 scrum">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-cspo.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div
                  className="media-body mt-3 mt-md-0"
                  style={{ paddingTop: 5 }}
                >
                  <h5 className="bold mt-0 mb-1">
                    Certified Scrum Product Owner
                  </h5>
                  <p className="m-0">
                    We have certified professionals as Product Owners. They have
                    the skill to prioritize your product needs based on the best
                    practices of an Agile Framework.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                {/* <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-symf.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                 <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">PHP Symfony</h5>
                  <p className="m-0">
                    More than Agile, our developers have Symfony certifications.
                    All sets of PHP components, web application, framework, and
                    best practices included.
                  </p>
                </div> */}
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-ctfl.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">
                    Certified Tester Foundation Level
                  </h5>
                  <p className="m-0">
                    ISTQB Certified Tester is a standardized qualification for
                    software testers and the certification is offered by the
                    ISTQB. The qualifications are based on a syllabus, and there
                    is a hierarchy of qualifications and guidelines for
                    accreditation and examination.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-cidm.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">
                    Certified in Distributed Management
                  </h5>
                  <p className="m-0">
                    CiDM from Remote-How is a certification for professionals
                    that had specific training for managing distributed teams,
                    including tactics, strategies, team building, communication,
                    and performance.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-sec.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">
                    Certified in Cyber Security
                  </h5>
                  <p className="m-0">
                    CSFPC is a standard Cyber Security Certification that covers
                    Risk Management, Law and Regulations, Human Factors, Privacy
                    and Online Rights, Malware and Attack technologies as well
                    as Incident Management.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <Fade flip delay={200}>
                  <img
                    src={require(`assets/img/screens/portfolio/badge-symf.png`)}
                    alt="..."
                    className="hive"
                  />
                </Fade>
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Certified in Symfony 5</h5>
                  <p className="m-0">
                    Our team has Symfony v5 certification, which includes a set
                    of skills that consists of the best web development
                    techniques in PHP technology.
                  </p>
                </div>
              </li>
            </ul>
          </Col>

          <Col md="6">
            <div className="bubble bubble-left center-x-md">
              <Zoom bottom>
                <figure className="rounded overflow-hidden shadow" />
              </Zoom>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesCantMiss;
