export const opt_workPreferences = [
  {
    value: "contratato",
    label: "Contrato",
    group: "workpreference",
  },
  { value: "freelance", label: "Freelance", group: "workpreference" },
  { value: "temporario", label: "Temporário", group: "workpreference" },
];

export const opt_expertise = [
  {
    value: "scm",
    label: "Gerente de Projetos",
    group: "expertise",
  },
  { value: "devphp", label: "Desenvolvedor PHP", group: "expertise" },
  { value: "qa", label: "Analista Qualidade", group: "expertise" },
  { value: "react", label: "Desenvolvedor React", group: "expertise" },
  {
    value: "reactnat",
    label: "Desenvolvedor React Native",
    group: "expertise",
  },
  { value: "front", label: "Frontend", group: "expertise" },
  { value: "fullst", label: "Full Stack", group: "expertise" },
];

export const groupedOptions = [
  {
    label: "work",
    options: opt_workPreferences,
  },
  {
    label: "expertise",
    options: opt_expertise,
  },
];
