import React, { Component } from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
function onChange(value) {
  console.log("Captcha value:", value);
}

require("dotenv").config();

class UPContact extends Component {
  constructor({ props }) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      returnMessage: "",
      submittedClass: "  ",
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();

    data.append("action", "getintouch");
    data.append("name", this.name.value);
    data.append("email", this.email.value);
    data.append("message", this.message.value);
    data.append("auth", process.env.REACT_APP_SENDGRID_API_KEY);

    fetch("/contact.php", {
      method: "POST",
      body: data,
    }).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson[0] === "success") {
          this.setState({ submittedClass: " submitted " });
        } else {
          alert("Sorry, something went wrong, please try again.");
          window.location.reload(false);
        }
      });
    });
  }

  render() {
    return (
      <section className="section stay-tunned">
        <Container className="bring-to-front">
          <div className="careerFront">
            <h5 className="bold text-alternate">
              Our head office is located in Bauru, SP, Brazil
            </h5>

            <p
              className="text-muted lead mx-auto"
              style={window.innerWidth <= 500 ? { marginBottom: 180 } : {}}
            >
              <strong>UP Outsourcing</strong>
              <br />
              R. Virgílio Malta, 17-76 - Sala 215
              <br />
              Vila Mesquita, Bauru - SP, 17014-440
              <br />
              Our timezone is UTC -3 hours.
              <br />
            </p>
          </div>


          <div className="shadow rounded p-5 bg-contrast overflow-hidden">
            <div className="mx-auto position-relative form-wrapper">
              <p className="text-muted lead mx-auto">
                Fill in the form below to schedule a call with us.
              </p>

              <form
                className={`form text-center ${this.state.submittedClass} `}
                data-response-message-animation="slide-in-left"
                onSubmit={this.handleSubmit}
              >
                <div className="form-group input--group">
                  <input
                    id="name"
                    type="name"
                    name="name"
                    className="form-control form-control-lg text-center"
                    placeholder="Enter your Name"
                    required
                    ref={(ref) => {
                      this.name = ref;
                    }}
                  />
                  <br />
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-control form-control-lg text-center"
                    placeholder="Enter your email"
                    required
                    ref={(ref) => {
                      this.email = ref;
                    }}
                  />
                  <br />
                  <textarea
                    id="message"
                    name="message"
                    className="form-control form-control-lg text-center"
                    placeholder="Enter the main subject"
                    required
                    ref={(ref) => {
                      this.message = ref;
                    }}
                  />
                </div>
                <div
                  className="recaptcha-area"
                  style={
                    window.innerWidth <= 768 ? { flexDirection: "column" } : {}
                  }
                >
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={onChange}
                  />
                  <button
                    type="submit"
                    className="btn btn-lg btn-alternate"
                    style={
                      window.innerWidth <= 768
                        ? { marginTop: 10 }
                        : { marginLeft: 40 }
                    }
                  >
                    Send
                  </button>
                </div>
              </form>

              <div className="response-message  ">
                <FontAwesomeIcon icon={["fas", "envelope"]} className="fa-3x" />
                <p className="font-md m-0">Sent!</p>
                <p className="response">
                  Thank you! Soon we will get in touch. <br />
                  <strong>Thank you!</strong>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  }
}
export default UPContact;