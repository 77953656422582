import React from "react";
// import UPServices from "../shared/UPServices.jsx";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
// import { Container } from "reactstrap";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";
const isMobile = window.innerWidth <= 768;

export default class Services extends React.Component {
  text = {
    title: "Agile Development",
    subtitle: "Fast and Better each cycle"
  };
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      // activeTab: "1"
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          {/* <UPServices /> */}

          <section className="section why-choose-us">
            <div className="shapes-container">
              <div className="pattern pattern-dots" />
            </div>

            <div className="shape shape-ring shape-ring-1">
              <div className="animation animation--rotating" />
            </div>
            <div className="shape shape-ring shape-ring-2">
              <div className="animation animation--rotating" />
            </div>
            <div className="shape shape-circle animation--clockwise">
              <div />
            </div>

            <div className="container pb-8 bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <h4 className="bold text-alternate">
                    Agile Development: Scrum / Kanban
                  </h4>

                  <p
                    className="text-muted lead leadExtended mx-auto"
                    style={
                      window.innerWidth <= 768
                        ? window.innerWidth <= 500
                          ? { width: "380px" }
                          : { width: "700px" }
                        : {}
                    }
                  >
                    These are the leading Agile Practices we use to develop
                    software. Scrum is an agile framework, which means it
                    contains best practices based on meetings, planning,
                    retrospectives, and frequent updates for the customer and
                    the team to develop with continuous delivery.
                    <br />
                    Most of the tasks are broken in a list based on a timeframe
                    that makes sense for the development team and the customer.
                  </p>
                  <div>
                    <Nav
                      tabs
                      style={
                        window.innerWidth <= 768
                          ? window.innerWidth <= 500
                            ? {}
                            : { width: "700px" }
                          : {}
                      }
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          More about Scrum Development
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          More about KanBan Development
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <p
                              className="text-muted lead leadExtended mx-auto"
                              style={
                                window.innerWidth <= 768
                                  ? window.innerWidth <= 500
                                    ? { width: "380px" }
                                    : { width: "700px" }
                                  : {}
                              }
                            >
                              Scrum has a measurable performance which is also
                              affected by the team experience. Everyone that is
                              part of the team also works with cross-functional
                              and self-organizing modes with clearly defined
                              rules and goals. The more the team develops
                              united, the better the performance also known as
                              an index of complexity called Capacity.
                              <br />
                              The manager is a word that does match this
                              development framework. The correct name for that
                              is Scrum Master, which leads the team and improves
                              the performance, removing all possible impediments
                              as soon as possible.
                              <br />
                              Product Owner is responsible for the Product
                              Backlog, which means: he is going to itemize all
                              work to be done, based on the priority that
                              matches the company and requests the development
                              for each sprint.
                            </p>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="6">
                            <p
                              className="text-muted lead leadExtended mx-auto"
                              style={
                                window.innerWidth <= 768
                                  ? window.innerWidth <= 500
                                    ? { width: "380px" }
                                    : { width: "700px" }
                                  : {}
                              }
                            >
                              Kanban works using a visual board, which is
                              divided into various columns that represent
                              varying levels of completeness of a task (e.g., to
                              do, doing, done). Each task is written on a card.
                              The prioritization of these tasks is going to
                              define which will be done first.
                            </p>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <br />
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0"
                      href="/services"
                    >
                      ... More Services
                    </a>
                    <a
                      className="more-link btn btn-outline-alternate more-link mt-0 padding-left-btn-touch"
                      href="/contato"
                    >
                      Get in touch
                    </a>
                  </div>
                  {isMobile && <div className="marginForMobile"></div>}
                </div>

                {!isMobile && (
                  <div className="col-md-6">
                    <div className="rotated-mockups device-twin">
                      <div className="browser absolute shadow-lg">
                        <img
                          src={require("assets/img/screens/portfolio/real_report_down.png")}
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
