import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
// import useWindowDimensions from "../../hooks/useWindowDimensions";

// reactstrap components
import { NavbarBrand, NavItem, NavLink, Nav, Container } from "reactstrap";
import userLocale from "../../utils/userLocale";

const showProfile =
  localStorage.getItem("linkedin_id") !== "" &&
  localStorage.getItem("linkedin_id") !== null;

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarExpanded: false,
      scrolled: false,
    };

    this.navbar = React.createRef();
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.userLanguage = userLocale.getLanguage();
    this.translations = require("../../translations/Navbars/DefaultNavbar.json")[this.userLanguage];
  }

  toggleNavbar() {
    this.setState((prevState) => ({
      navbarExpanded: !prevState.navbarExpanded,
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const navigation = this.navbar.current;
    const navTop = navigation.offsetTop + navigation.offsetHeight;

    if (window.scrollY >= navTop) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  logout = () => {
    localStorage.removeItem("linkedin_id");
    this.props.history.push({
      pathname: "/careers",
    });
  };

  render() {
    const styleNavbar = this.state.scrolled ? { zIndex: 9999 } : { zIndex: 1 };
    const useOnlyDarkLogo = this.props.useOnlyDarkLogo;
    return (
      <nav
        ref={this.navbar}
        className={classNames(
          "navbar navbar-expand-md main-nav navigation fixed-top sidebar-left navbar-sticky",
          { "navbar-expanded": this.state.navbarExpanded }
        )}
        style={styleNavbar}
      >
        <Container>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavbar}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>

          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            {useOnlyDarkLogo ? (
              <img
                alt="..."
                src={require("assets/img/logo.png")}
                className="logo logo-sticky"
              />
            ) : (
              <>
                <img
                  src={require("assets/img/logo2.png")}
                  alt="UP Outsourcing"
                  className="logo logo-sticky d-block d-md-none"
                  style={{ maxHeight: 50 }}
                />
              </>
            )}
          </NavbarBrand>

          <div className="collapse navbar-collapse">
            <div className="sidebar-brand">
              <Link to="/">
                <img
                  src={require("assets/img/logo2.png")}
                  alt="UP Outsourcing"
                  className="logo"
                />
              </Link>
            </div>

            <Nav
              className="nav navbar-nav ml-auto"
              navbar
              style={window.innerWidth <= 768 ? { marginRight: -25 } : {}}
            >
              <NavItem>
                <NavLink href={this.translations['route.home']}>{this.translations['menu.home']}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={this.translations['route.about']}>{this.translations['menu.about']}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={this.translations['route.services']}>{this.translations['menu.services']}</NavLink>
              </NavItem>
              {/* 
              <NavItem>
                <NavLink href="/clients">Clients</NavLink>
              </NavItem>
                            
              <NavItem>
                <NavLink href="/team">More about us</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href={this.translations['route.careers']}>{this.translations['menu.careers']}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/bancodetalentos">Talent Pool</NavLink>
              </NavItem>
              {showProfile && (
                <NavItem>
                  <NavLink href="/profile">My Profile</NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink href={this.translations['route.contact']}>{this.translations['menu.contact']}</NavLink>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </nav>
    );
  }
}

export default DefaultNavbar;
