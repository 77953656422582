import React from "react";
import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import SubHeading from "../alter3/SubHeading.jsx";

const isMobile = window.innerWidth <= 500;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

require("dotenv").config();

const showProfile = process.env.REACT_APP_SHOW_PROFILE + "";

const phpserver =
  process.env.REACT_APP_PROXY + process.env.REACT_APP_LINKEDIN_CALLBACK;

class Career extends React.Component {
  constructor(props) {
    super(props);
    const restoredLinkedin_id = localStorage.getItem("linkedin_id");

    this.state = {
      code: "",
      errorMessage: "",
      access_token: "",
      first_name: "",
      last_name: "",
      linkedin_id: restoredLinkedin_id,
      profile_picture: "",
      time_out: "",
      loading: false,
      disabled: false,
      file: "",
      formPostbackMessage: "",
      submittedClass: " ",
      displayLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFailure = (error) => {
    this.setState({ code: "", errorMessage: error.errorMessage });
    this.setState({ displayLoading: false });
  };

  text = {
    title: "Elixir",
    subtitle: "Conheça mais sobre essa linguagem que vem ganhando força no mercado",
  };

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData();
    this.setState({ formPostbackMessage: "" });
    this.setState({ displayLoading: true });
    // loading
    this.setState({
      loading: true,
      actionBtns: true,
    });

    data.append("action", "addByForm");
    data.append("linkedin_id", localStorage.getItem("linkedin_id"));
    data.append("nomecompleto", this.nomecompleto.value);
    data.append("email", this.email.value);
    data.append("github", this.github.value);
    data.append("file", this.arquivo.files[0]);

    fetch(phpserver + "user_registration.php", {
      // mode: "no-cors",
      method: "POST",
      body: data,
    }).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson[0] === "success") {
        } else {
          this.setState({ formPostbackMessage: responseJson[1] });

          return false;
        }
      });
    });
  }

  render() {
    const { code, errorMessage, formPostbackMessage } = this.state;

    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}

          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <section className="section why-choose-us ">
            {!code && (
              <Container className="bring-to-front ">
                <div className="shapes-container ">
                  <div className="pattern pattern-dots " />
                            </div>


                <div
                  className={
                    this.state.displayLoading ? "sweet-loading" : "hiddenDiv"
                  }
                >
                  <ScaleLoader
                    css={override}
                    size={150}
                    //size={"150px"} this also works
                    color={"#073039"}
                    loading="true"
                    // {this.state.loading}
                  />
                </div>
                <div
                  className={
                    this.state.displayLoading
                      ? "hiddenDiv"
                      : "container pb-8 bring-to-front lessMarginTop actionBtns"
                  }
                            >
                                
                                <div className="row gap-y">
                    
                    <div className="col-md-7">

                     
                      <img
                        src={require("assets/img/Official_Elixir_logo.png")}
                        alt="..."
                      />

                      <p className="text-muted lead mx-auto">



                       
                       
                        <br /><br />
                        Estamos trabalhando em projeto que vai migrar todo código base de PHP para o Elixir, otimizando muito o poder de processamento e melhorar a entrega das informações de forma muito mais rápida.
                        <br /><br />
                        É uma grande oportunidade para quem quer trabalhar com arquiteturas que suportam milhões de usuários por mês, com uma alta frequência de uso, em uma empresa norte americana.
                        Além de toda ajuda necessária para a migração do PHP para Elixir, outros fatores como aprender e usar o inglês (Business), interagir com os diversos times de desenvolvimento e crescer
                        muito profissionalmente - estão inclusos nessa oportunidade.
                        <br /><br />
                        O Elixir é uma linguagem dinâmica e funcional para construir aplicações escaláveis e de fácil manutenção. Foi criado pelo Brasileiro <strong>José Valim</strong>.
                        <br /><br />
                        O Elixir veio da maquina virtual Erlang (criada pela Ericsson) e utilizada há várias décadas no nosso sistema de telefonia.
                        É uma linguagem robusta que foca em disponibilidade, escalabilidade, tolerância a falhas e foi amplamente testada.
                        <br /><br />
                        Tecnicamente falando, são várias razões para se atentar ao crescimento do Elixir, dentre elas:
                        <br /> <br />
                        <ul>
                          <li>
                            <strong> Simplicidade</strong>: Na programação funcional so existem funções, a forma de pensar é diferente da orientação a objeto, mas o resultado é muito melhor e otimizado, além da facilidade de se aprender;
                           </li>
                          <li>
                            <strong>Imutabilidade</strong>: Facilita manusear todo o poder computacional da maquina ou equipamento.
                            A imutabilidade permite segurança de memória, de forma que o processamento assí­ncrono, paralelo ou concorrente não causem problemas na memória.
                          </li>
                          <li>
                            <strong>Um novo paradigma</strong>: um profissional de Elixir pode ser considerado um profissional mais maduro e que sabe novas formas de escrever código, além da Orientação a Objeto.
 </li>
                          <li>
                            <strong>O código é muito amigável</strong>: pode-se dizer que é a melhor linguagem com suporte a ferramentas de teste, formatação de código, alto ní­vel, de escrita elegante e altamente legí­vel.
                            </li>
</ul>

                        <br />Sobre o Elixir: 
                        <br />

                        <a href="https://pt.wikipedia.org/wiki/Elixir_(linguagem_de_programa%C3%A7%C3%A3o" target="_blank">  Elixir na Wikipedia </a>
                        <br /> <br />
                        Sobre o criador, Jose Valim <br />
                        <a href="https://pt.wikipedia.org/wiki/Jos%C3%A9_Valim" target="_blank">  José Valim na Wikipedia </a>
                        <br />  <br />
                        Código Fonte <br />
                        <a href="https://github.com/elixir-lang/elixir" target="_blank"> Elixir GitHub </a>




                     
                      </p>

                      
                                    </div>

                                  
                    {!isMobile && (
                      <div className="col-md-1">
                        <div className="rotated-mockups device-twin">
                          <div className="browser absolute shadow-lg">
                            <img
                              src={require("assets/img/screens/portfolio/resume.png")}
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            )}
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Career;
