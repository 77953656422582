import React from "react";
import UPServices from "../shared/UPServices.jsx";

import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";

export default class Services extends React.Component {
  text = {
    title: "Services",
    subtitle: "See how can we help your Company"
  };

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <UPServices />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

// export default Services;
