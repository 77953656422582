import React from "react";
import UPTeam from "../shared/UPTeam.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import SubHeading from "../alter3/SubHeading.jsx";

class Team extends React.Component {
  text = {
    title: "Team",
    subtitle: "Meet our great team"
  };

  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <UPTeam />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Team;
