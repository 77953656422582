import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import PowerfulCard from "./PowerfulCard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const createCards = use => {
  let features = [];
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto ",
      icon: "paint-bucket",
      title: "Scrum / Kanban",
      animation: { delay: 0 },
      description:
        "Collaborative effort, Self-organizing and cross-functional teams. ",
      url: "/services",
      image: "scrum.png"
    },

    {
      class: "mx-lg-auto ",
      icon: "light",
      title: "Startups",
      animation: { delay: 800 },
      description: "We can help prototyping your idea and launch asap.",
      url: "/services",
      image: "startups.png"
    },

    {
      class: "mr-lg-auto",
      icon: "diamond",
      title: "Consulting",
      animation: { delay: 500 },
      description: "Companies have custom needs. We can provide help!",
      url: "/services",
      image: "consulting.png"
    },

    {
      class: "mt-6n mr-lg-auto",
      icon: "cash",
      title: "Management",
      animation: { delay: 1200 },
      description:
        "Taking care of Development Teams is something we do for years.",
      url: "/services",
      image: "management.png"
    }
  ];

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />);
    }
  });

  return features;
};

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <div className="container">
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "shipping-fast"]}
                className="fa-3x accent mb-3"
              />
              <h3 className="text-alternate heading-line">Agile Mindset</h3>

              <p>
                Team Collaboration, Improvement, Learning Cycles, Delivering
                Real Value, Adapt, and Change is the main goals of Agile
                Development. Based on the Scrum framework, we can deliver
                software fast and what matters to the customer.
              </p>
              <Link to="/services" className="btn btn-primary text-contrast">
                Learn more about our process
              </Link>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default PowerfulFeatures;
