import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const renderMockups = () => {
  let mockups = [];
  let items = [
    {
      class: "tablet ipad landscape",
      img: "portfolio/tablet-audit.png",
      button: "button"
    },
    {
      class: "iphone light phone-big",
      img: "portfolio/mobile-rc.png",
      button: "notch"
    },
    {
      class: "iphone light phone-small",
      img: "portfolio/mobile-travelpass.png",
      button: "notch"
    },
    {
      class: "tablet ipad portrait",
      img: "portfolio/analytics.png",
      button: "button"
    }
  ];

  items.forEach((mockup, i) => {
    mockups.push(
      <div className={mockup.class} key={i}>
        <div className="screen">
          <img src={require(`assets/img/screens/${mockup.img}`)} alt="..." />
        </div>
        <div className={mockup.button} />
      </div>
    );
  });

  return mockups;
};

const IsometricMockups = () => {
  const { width } = useWindowDimensions(); //height,
  return (
    <section className="isometric-mockups">
      {width >= 1024 && renderMockups()}
    </section>
  );
};

export default IsometricMockups;
