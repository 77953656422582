import React from "react";
import { Container } from "reactstrap"; //Row, Col, UncontrolledCollapse
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
// alternative 3 page components
import { ScaleLoader } from "react-spinners";
import SubHeading from "./SubHeading.jsx";
// import Select from "react-select";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPollH,
  faBackspace,
  faCheck,
  faUserCog,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faTumblr } from "@fortawesome/free-brands-svg-icons";
import { opt_workPreferences, opt_expertise } from "../../data";

require("dotenv").config();

const animatedComponents = makeAnimated();

class Career extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      first_name: "...",
      last_name: "...",
      linkedin_id: "",
      profile_picture: require("assets/img/account-hexagon-512.png"),
      loading: false,
      disabled: false,
      filename: "",
      email: "",
      profileform: "",
      profiledatesent: "",
      displayData: false,
      displayPreferencesLoading: false,
      displayPreferencesLoadingBTN: true,
      workPreferences: [],
      expertise: [],
      github: "",
      postbackStatus: "",
      defaultValue_expertise: [],
      defaultValue_workPreferences: [],
    };

    this.scheduleTest = this.scheduleTest.bind(this);
    this.handleUploaFile = this.handleUploaFile.bind(this);
    this.saveIt = this.saveIt.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  logout = () => {
    localStorage.removeItem("linkedin_id");
    localStorage.removeItem("code");
    this.props.history.push({
      pathname: "/careers",
    });
  };

  fetchUserPHP = () => {
    const code = localStorage.getItem("code");

    if (code === "" || !code) {
      this.props.history.push({
        pathname: "/login",
      });
    }
    const dataPost = {
      code: code,
      action: "preRegisterUser",
    };

    fetch(process.env.REACT_APP_USER_REGISTRATION, {
      // mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json, text/plain ",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(dataPost),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0] === "success" && responseJson[1] !== "") {
          // localStorage.removeItem("code");
          localStorage.setItem("internalToken", responseJson[1]);
          this.getUserDetails();
        }
      })
      .catch((error) => {
        this.setState({ displayLoading: false });
        console.error(error);
      });
  };

  getUserDetails = () => {
    /**
     * Workaround for dev
     */

    const dataPost = {
      internalToken: localStorage.getItem("internalToken"),
      action: "getUserDetails",
    };

    fetch(process.env.REACT_APP_USER_REGISTRATION, {
      // mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json, text/plain ",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(dataPost),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["status"] === "success") {
          console.log(
            "user workpref: " +
              responseJson.workpreferences +
              "    user expert: " +
              responseJson.expertise
          );
          let responseworkpreferences = responseJson.workpreferences
            .toString()
            .split(",");
          let responseexpertise = responseJson.expertise.toString().split(",");

          this.setState(
            {
              first_name: responseJson.first_name.trim(),
              last_name: responseJson.last_name.trim(),
              profile_picture: responseJson.profile_picture.trim(),
              email: responseJson.email.trim(),
              filename: responseJson.filename.trim(),
              profileform: responseJson.profileform.trim(),
              profiledatesent: responseJson.profiledatesent,
              github: responseJson.github,
              workpreferences: responseworkpreferences,
              expertise: responseexpertise,
              displayData: true,
              defaultValue_expertise: [],
              defaultValue_workPreferences: [],
            },
            () => {
              this.buildSelects();
            }
          );
        } else {
          this.setState({ displayLoading: true });
          console.log(responseJson[1]);
        }
      })
      .catch((error) => {
        this.setState({ displayLoading: false });
        console.error(error);
      });
  };

  componentDidMount() {
    /**
     * work around for design
     */

    if (window.location.port === "3000") {
      this.setState(
        {
          first_name: "Gilnei",
          last_name: "Longo",
          profile_picture:
            "https://media-exp1.licdn.com/dms/image/C4D03AQE9gihhfF-I5Q/profile-displayphoto-shrink_100_100/0?e=1596067200&v=beta&t=Oyk_4aBQBjU83qO-CFSBbZtebJhiXSvd1ZCuTMZOPLY",
          email: "gilnei@gmail.com",
          displayData: true,
          profileform: 0,
          github: "http://gilnei.com",
          workpreferences: ["contratato", "freelance"],
          expertise: ["scm", "devphp"],
          defaultValue_expertise: [],
          defaultValue_workPreferences: [],
        },
        () => {
          this.buildSelects();
        }
      );
    } else {
      if (
        localStorage.getItem("internalToken") !== "" &&
        localStorage.getItem("internalToken") !== null
      ) {
        // there is a token, don't need to revalidate
        this.getUserDetails();
      } else {
        this.fetchUserPHP();
      }
    }
  }

  scheduleTest() {
    confirmAlert({
      title: "Teste de Perfil Profissional",
      message:
        "Você vai receber um e-mail com o link para o teste em breve. O link leva para um formulário do Google (externo a este site) ",
      buttons: [
        {
          label: "Enviar",
          onClick: () => {
            const options = {
              crossdomain: true,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "X-Requested-With",
              },
            };

            const dataPost = {
              action: "sendProfileForm",
              internalToken: localStorage.getItem("internalToken"),
            };

            fetch(process.env.REACT_APP_USER_REGISTRATION, {
              cache: "no-store",
              method: "POST",
              headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type, X-Request-With",
              },
              options,
              body: JSON.stringify(dataPost),
            })
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson.status === "sent") window.location.reload();
              })
              .catch((error) => {
                alert(error);
              });
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  }

  handleChange = (selectedOption) => {
    if (selectedOption === null) return false;
    const l_workPreferences = [];
    const l_expertise = [];
    let updatework = false;
    let updateex = false;

    const workpref = selectedOption.map((item, key) => {
      if (item.group === "workpreference") {
        l_workPreferences.push(item.value);
        updatework = true;
      }
      if (item.group === "expertise") {
        l_expertise.push(item.value);
        updateex = true;
      }
    });

    if (updatework === true) {
      this.setState({ workPreferences: l_workPreferences });
    }
    if (updateex === true) {
      this.setState({ expertise: l_expertise });
    }
  };

  saveIt(e) {
    e.preventDefault();
    this.setState({ displayPreferencesLoading: true });
    this.setState({ displayPreferencesLoadingBTN: false });

    const dataPref = new FormData();
    dataPref.append("internalToken", localStorage.getItem("internalToken"));
    dataPref.append("action", "savePreferences");
    dataPref.append("workPreferences", this.state.workPreferences);
    dataPref.append("expertise", this.state.expertise);
    dataPref.append("github", this.state.github);

    console.log(process.env.REACT_APP_USER_REGISTRATION);

    fetch(process.env.REACT_APP_USER_REGISTRATION, {
      method: "POST",
      body: dataPref,
    }).then((response) => {
      response.json().then((responseJson) => {
        console.log(response);
        if (responseJson[0] === "success") {
          this.setState({
            displayPreferencesLoading: false,
            displayPreferencesLoadingBTN: true,
            postbackStatus: responseJson[1],
          });
        } else {
          alert("Erro salvando, por favor tente novamente.");
          this.setState({ displayPreferencesLoading: false });
          this.setState({ displayPreferencesLoadingBTN: true });
        }
      });
    });
  }
  removeResume() {
    confirmAlert({
      title: "Confirme",
      message: "Remover o currículo?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const dataPost = {
              action: "deleteResume",
              internalToken: localStorage.getItem("internalToken"),
            };

            fetch(process.env.REACT_APP_USER_REGISTRATION, {
              cache: "no-store",
              method: "POST",
              headers: {
                Accept: "application/json, text/plain ",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "X-Requested-With": "XMLHttpRequest",
              },
              body: JSON.stringify(dataPost),
            })
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson.status === "deleted") window.location.reload();
              })
              .catch((error) => {
                alert(error);
              });
          },
        },
        {
          label: "Nao",
          onClick: () => {},
        },
      ],
    });
  }
  handleUploaFile(ev) {
    ev.preventDefault();

    if (this.uploadInput.files[0]) {
      const data = new FormData();
      data.append("file", this.uploadInput.files[0]);
      data.append("internalToken", localStorage.getItem("internalToken"));

      fetch(process.env.REACT_APP_USER_FILEUPLOAD, {
        method: "POST",
        body: data,
      }).then((response) => {
        response.json().then((responseJson) => {
          // console.log(response);
          if (responseJson[0] === "success") {
            window.location.reload();
          }
        });
      });
    } else {
      // alert("Escolha um arquivo antes");
      this.uploadInput.click();
    }
  }

  buildSelects() {
    let counter = 0;
    let newAr = [];
    const expertisedrop = opt_expertise.map((item, key) => {
      const localExpertise = this.state.expertise.map((iteme, keye) => {
        if (item.value === iteme) {
          newAr[counter++] = item;
        }
      });
    });
    this.setState({ defaultValue_expertise: newAr });

    counter = 0;
    newAr = [];
    const workprefDrop = opt_workPreferences.map((item, key) => {
      const localworkPref = this.state.workpreferences.map((iteme, keye) => {
        if (item.value === iteme) {
          newAr[counter++] = item;
        }
      });
    });
    this.setState({ defaultValue_workPreferences: newAr });
  }
  text = {
    title: "Profile",
    subtitle: "Perfil Profissional",
  };

  render() {
    let self = this;
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}

          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <section className="section stay-tunned">
            <Container className="bring-to-front lessMarginTop">
              {/* <div className="container pb-8 bring-to-front lessMarginTop actionBtns"> */}
              <div className={self.state.displayData ? "hiddenDiv" : ""}>
                <ScaleLoader
                  size={150}
                  //size={"150px"} this also works
                  color={"#073039"}
                  loading="true"
                  // {this.state.loading}
                />
              </div>
              <div
                className={self.state.displayData ? "col-md-6" : "hiddenDiv"}
              >
                <div className="rounded media bg-contrast shadow-lg p-4 ">
                  <div className="icon-shape mr-4">
                    <img src={self.state.profile_picture} alt="..." />
                  </div>
                  <div className="media-body">
                    <h4 className="text-primary">
                      {self.state.first_name} {self.state.last_name}
                      <a
                        href="/#"
                        onClick={this.logout}
                        className="btn btn-link"
                      >
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          className="fa-1x accent"
                        />
                      </a>
                    </h4>

                    {this.state.email}
                  </div>
                </div>
                <div className="rounded media bg-contrast shadow-lg p-4">
                  <div className="icon-shape mr-4">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fa-3x accent mb-3 paddingX"
                    />
                  </div>
                  <div className="media-body">
                    <h5 className="text-primary">Currículo</h5>

                    {this.state.filename !== "" && (
                      <div>
                        {this.state.filename}
                        <br />
                        <a href="#" onClick={this.removeResume}>
                          <FontAwesomeIcon
                            icon={faBackspace}
                            className="fa-1x text-primary mr-2"
                          />
                        </a>
                      </div>
                    )}
                    {this.state.filename === "" && (
                      <div>
                        <p className="text-muted lead mx-auto">
                          Você pode enviar um arquivo PDF com seu currículo para
                          nosso processo seletivo, quando houver disponibilidade
                          entraremos em contato.
                        </p>

                        <form onSubmit={this.handleUploaFile}>
                          <div>
                            <input
                              ref={(ref) => {
                                this.uploadInput = ref;
                              }}
                              type="file"
                            />
                          </div>
                          <div>
                            <button
                              type="submit"
                              ref={(ref) => {
                                this.sendBtn = ref;
                              }}
                              className="btn btn-primary text-contrast"
                            >
                              Enviar
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>

                <div className="rounded media bg-contrast shadow-lg p-4">
                  <form onSubmit={this.saveIt.bind(this)}>
                    <div className="icon-shape mr-4">
                      <FontAwesomeIcon
                        icon={faUserCog}
                        className="fa-3x accent mb-3 paddingX"
                      />
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="hiddenDiv"
                        // fa-3x accent mb-3 paddingX
                        id="preferences_loading"
                      />
                    </div>

                    <div className="media-body">
                      <h5 className="text-primary">Preferências </h5>
                      <div>
                        <p className="text-muted lead mx-auto">
                          Escolha o tipo de serviço que você pode oferecer:
                        </p>
                      </div>

                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti={true}
                        options={opt_workPreferences}
                        onChange={this.handleChange}
                        name="workPreferences"
                        id="workPreferences"
                        value={this.state.defaultValue_workPreferences}
                        // defaultValue={this.state.defaultValue_workPreferences}
                      />
                      <div>
                        <p className="text-muted lead mx-auto">
                          Escolha sua(s) especialidade(s):
                        </p>
                      </div>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti={true}
                        options={opt_expertise}
                        onChange={this.handleChange}
                        name="expertise"
                        id="expertise"
                        value={this.state.defaultValue_expertise}
                        // defaultValue={this.state.defaultValue_expertise}
                      />
                      <div>
                        <p className="text-muted lead mx-auto">Github:</p>
                        <input
                          id="github"
                          type="text"
                          name="github"
                          className="form-control form-control-lg text-center"
                          placeholder="Seu endereço do Github"
                          ref={(ref) => {
                            this.name = ref;
                          }}
                          value={this.state.github}
                        />
                        <br />

                        <div
                          className={
                            self.state.displayPreferencesLoading
                              ? ""
                              : "hiddenDiv"
                          }
                        >
                          <ScaleLoader
                            size={150}
                            color={"#0F6E84"}
                            loading="true"
                          />
                        </div>
                        <div
                          className={
                            self.state.displayPreferencesLoadingBTN
                              ? ""
                              : "hiddenDiv"
                          }
                        >
                          <button
                            type="submit"
                            ref={(ref) => {
                              this.sendBtn = ref;
                            }}
                            className="btn btn-primary text-contrast"
                          >
                            Salvar
                          </button>
                          &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          {this.state.postbackStatus}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="rounded media bg-contrast shadow-lg p-4">
                  <div className="icon-shape mr-4">
                    <FontAwesomeIcon
                      icon={faPollH}
                      className="fa-3x accent mb-3 paddingX"
                    />
                  </div>

                  <div className="media-body">
                    <h5 className="text-primary">Testes </h5>
                    <p className="text-muted lead mx-auto"></p>
                    Os questionários a seguir podem ser preenchidos para
                    entendermos seu perfil e facilitar as futuras oportunidades.
                    Consulte nossa{" "}
                    <a href="/privacy-policy" target="_blank">
                      Politica de Segurança
                    </a>{" "}
                    para maiores informações.
                    {this.state.profileform === 0 && (
                      <div>
                        <a
                          href="#"
                          onClick={this.scheduleTest.bind(this)}
                          className="more-link btn btn-outline-primary bold"
                        >
                          Agende seu teste de Perfil Profissional
                        </a>
                      </div>
                    )}
                    {this.state.profileform === "1" && (
                      <div>
                        <p>
                          Seu teste foi enviado em: {this.state.profiledatesent}
                        </p>
                        <a
                          href="/#"
                          onClick={this.scheduleTest.bind(this)}
                          className="more-link btn btn-outline-primary bold"
                        >
                          Reenvie seu teste
                        </a>
                      </div>
                    )}
                    {this.state.profileform === "2" && (
                      <div>
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="fa-1x accent mb-3 paddingX"
                        />
                        Teste respondido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Container>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Career;
