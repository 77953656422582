import React from "react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MoreInfoButton from "components/MoreInfoButton";
const isMobile = window.innerWidth <= 700;

const UPAbout = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              We are 20+ years experienced as technology professionals
            </h4>
            <p className="text-muted lead mx-auto">
              We are a Web Development Outsourcing Company located in Brazil,
              more precisely in Bauru, São Paulo.
              <br /> <br />
              We build web development teams to support your product or idea.
              Using Agile Methods, we are able to provide a continuous
              incremental delivery to your product or idea towards to your
              goals. We area ablet o build teams with managers as well. And,
              also, handle all the bureaucracy.
              <br /> <br />
              We have already managed teams overseas such as India for years.
              <br />
              <br />
              We do have our own internal hiring process that is not strictly
              onyl on years of experience of the candidate.
              <br />
              <br />
              We do use Agile methods or frameworks to delivery as fast as
              possible for our customer and also to be able to handle team and
              product changes.
              <br />
              <br />
              <div className="col-md-15 why-choose-us">
                <div className="shadow rounded p-3 bg-contrast overflow-hidden">
                  <div className=" text-center">
                    <p className="text-muted lead mx-auto">
                      <i>
                        " I have worked with the Up Outsourcing team for several
                        years now (...) I plan to use them for many years to
                        come."
                      </i>
                    </p>
                    <br />
                    <b> Rebus Marketing LLC/Bryce Miller, 2020</b>
                  </div>
                </div>
              </div>
            </p>
            <br /> <br />
            <h4 className="bold text-alternate">
              We don't hire resources, we hire people
            </h4>
            <p className="text-muted lead mx-auto">
              Our team is built according to our customer needs and each team
              member profile is evaluated before. Our interview goes more than
              technical tests. We also perform a profile test to understand
              better about the developer and we do evaluate each candidate work
              profile using our internal questionary.
              <br /> <br />
              We hire people who wants to make a difference and who really loves
              working with technology.
              <br /> <br />
              UP Outsourcing is a company from <br />
              <a href="https://www.linkedin.com/in/gilneilongo/">
                <FontAwesomeIcon icon={faLinkedin} /> Gilnei Longo
              </a>
              &nbsp; and &nbsp;
              <a href="https://www.linkedin.com/in/colafati">
                <FontAwesomeIcon icon={faLinkedin} /> Everton Colafati
              </a>
            </p>
            <br /> <br /> <br /> <br />
            <br /> <br /> <br /> <br />
          </div>
          {isMobile && <div className="marginForMobileServices"></div>}
          {!isMobile && (
            <div className="col-md-6">
              <div className="rotated-mockups device-twin">
                <div className="browser absolute shadow-lg">
                  <img
                    src={require("assets/img/screens/portfolio/bauru.jpg")}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default UPAbout;
